import React, { useContext } from "react";
import QRCode from "qrcode.react";
import { useTranslation } from "react-i18next";
import { MAIN_CONTAINER_HEIGHT, HEADER_HEIGHT } from "../App";
import { ExpoContext } from "../context/expoProvider";

const ExpoPreview = ({ width }) => {

  const { snackUrl, showPreview, setShowPreview, connectedDevices} = useContext(ExpoContext);

  const { t } = useTranslation();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(snackUrl);
    alert("Snack URL copied to clipboard!");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        width: width,
      }}
    >
      <div
        style={{
          height: MAIN_CONTAINER_HEIGHT - HEADER_HEIGHT,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: 20,
        }}
      >
        {snackUrl ? (
          <div style={{ display: "flex", flexDirection: "column", justifyContent:'center', alignItems:'center' }}>
            <QRCode value={snackUrl} size={170} />
            <span
              style={{
                textAlign: "center",
                fontSize: 14,
                paddingTop: 10,
              }}
            >
              {t("expo.scanQRCode")}
            </span>
            <div style={{ marginTop:20, }}>
              <span
                style={{
                  textAlign: "center",
                  fontSize: 14,
                  paddingTop: 10,
                }}
              >
                {t("expo.orOpenThisLinkWithExpo")}
                <a href={"https://expo.dev/go"} target="_blank" rel="noreferrer" style={{marginLeft:5, fontWeight: 'bold', fontSize: 12, textDecorationLine: 'underline'}}>
                Expo
                </a>
              </span>
              <button
                style={{
                  fontSize: 15,
                  padding: 10,
                  textDecoration: "underline",
                  fontWeight: "bold",
                  width: "100%",
                }}
                onClick={copyToClipboard}
              >
                {t("expo.copyUrl")}
              </button>
            </div>
            {
              connectedDevices.length > 0 && (
                <div>
                  <span
                    style={{
                      textAlign: "center",
                      fontSize: 14,
                      paddingTop: 10,
                    }}
                  >
                    {`${t("expo.connectedDevices")}`}
                  </span>
                  <div style={{ display: "flex", flexDirection: "column", justifyContent:'center', alignItems:'center' }}>
                    {connectedDevices.map((device, i) => (
                      <div key={i}>
                        <span>{`${device?.name} (${device?.platform})`}</span>
                      </div>
                    ))}
                  </div>
                </div>
              )
            }
          </div>
        ) : (
          <span style={{
            textAlign: "center",
            fontSize: 14,
            paddingTop: 10,
          }}>{t("expo.loadingQRCode")}</span>
        )}
      </div>
    </div>
  );
};

export default React.memo(ExpoPreview, (prevProps, nextProps) => {
  return (prevProps.width === nextProps.width);
});
