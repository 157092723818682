import React, { useState, useContext } from "react";
import SignUpWithGoogle from "../SignUpWithGoogle";
import { SERVER_URL, END_POINTS, API_KEY } from "../../../../src/constants";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

const SignupPage = () => {

  const { signup } = useContext(AuthContext);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onCreateAccount = async (user) => {
    setLoading(true);
    setError(null);

    const { displayName, email, photoURL } = user;

    try {
      const response = await fetch(`${SERVER_URL}${END_POINTS.SIGNUP}`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'apiKey': API_KEY,
        },
        body: JSON.stringify({
          displayName,
          email,
          photoURL,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data?.user) {
        const {email } = data.user;
        
        signup(email).then(()=>{
          navigate("/");
        })
        .catch()
        .finally(()=>{
          setLoading(false);
        });
        
      } else if (data?.error) {
        throw new Error(data.error);
      }
      
    } catch (error) {
      onError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onError = (error) => {
    if (error) {
      setError(error);
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <h2 style={styles.title}> {t("pages.signup.createAccount")}</h2>
        <p style={styles.subtitle}>{t("pages.signup.createAccountSubtitle")}</p>
        {error && <div style={styles.error}>{error}</div>}
        <SignUpWithGoogle
          label={t("pages.signup.signupButton")}
          onCreateAccount={onCreateAccount}
          onError={onError}
        />
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "white",
  },
  card: {
    background: "white",
    padding: "2rem",
    border: "1px solid #eee",
    borderRadius: "10px",
    // boxShadow: "0 4px 4px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    maxWidth: "400px",
    width: "100%",
  },
  title: {
    margin: "0 0 1rem",
    fontSize: "2rem",
    color: "#333",
  },
  subtitle: {
    margin: "0 0 2rem",
    fontSize: "1.2rem",
    color: "#666",
  },
  error: {
    backgroundColor: "#f8d7da",
    color: "#721c24",
    padding: "1rem",
    borderRadius: "5px",
    border: "1px solid #f5c6cb",
    marginBottom: "1rem",
    fontSize: "1rem",
    width: "100%",
    boxSizing: "border-box",
  },
};

export default SignupPage;
