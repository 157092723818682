import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { initializeApp } from "firebase/app";
import GoogleButton from "react-google-button";

const firebaseConfig = {
  apiKey: "AIzaSyBXf8hG66zFzYWTvzgHIAKQAzcQNH_6d7s",
  authDomain: "mc-qween.firebaseapp.com",
  projectId: "mc-qween",
  messagingSenderId: "660143355911",
  appId: "1:660143355911:web:7aa3fb4831efb609add2a2",
  measurementId: "G-08Q9J7VCLG",
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);

const provider = new GoogleAuthProvider();

const auth = getAuth();

const SignUpWithGoogle = ({onCreateAccount, onError, label}) => {
  const signUpWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        onCreateAccount({
          displayName: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
          token,
        })
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        onError(errorMessage);
        // ...
      });
  };

  return (
    <GoogleButton
      label={label}
      onClick={signUpWithGoogle}
      style={{
        width: "100%",
      }}
    />
  );
};

export default SignUpWithGoogle;
