import {
  createContext,
  useCallback,
  useMemo,
  useState,
  useRef,
  useEffect,
  useContext,
} from "react";
import { extractFirstCodeBlock } from "./chatUtils";
import { SERVER_URL, END_POINTS, INTIAL_CODE, API_KEY } from "../constants";
import { AuthContext } from "./AuthContext";
import { ExpoContext } from "./expoProvider";
import { v1 as uuidv1 } from "uuid";

export const ChatContext = createContext(null);

let currentController = new AbortController();

export const ChatProvider = ({ children }) => {
  const { user } = useContext(AuthContext);
  const { setCode } = useContext(ExpoContext);

  // #region State

  const [chat, setChat] = useState({
    id: uuidv1(),
    messages: []
  });
  const [loading, setLoading] = useState(false);

  // #region Refs

  const messagesRef = useRef([]);
  const userRef = useRef(user);
  const chatIdRef = useRef(chat.id);

  // #region hooks

  // #region Effects

  useEffect(() => {
    if(!user?.id){
      // Clean chat
      onCreateNewChat();
    }
  }, [user]);

  useEffect(() => {
    messagesRef.current = chat.messages;
    chatIdRef.current = chat.id;
    // console.log(JSON.stringify(messagesRef.current, null, 2));
  }, [chat.messages]);

  useEffect(() => {
    userRef.current = user;
  }, [user]);

  // #region Handlers

  const onCreateNewChat = useCallback(() => {
    setChat({
      id: uuidv1(),
      messages: []
    });
    setCode(INTIAL_CODE);
    if (currentController) {
      currentController.abort();
    }
  }, []);

  const sendMessageWithImage = useCallback(async (url) => {
    messagesRef.current = [
      ...messagesRef.current,
      {
        role: "user",
        content: [
          {
            type: "image_url",
            image_url: {
              detail: "high",
              url,
            },
          },
        ],
      },
    ];

    setChat((c) => ({...c, messages: messagesRef.current}));
    fetchChatStream(messagesRef.current, END_POINTS.IMAGE_TO_CODE);
  }, [chat.id]);

  const sendMessage = useCallback(async (input) => {
    messagesRef.current = [
      ...messagesRef.current,
      {
        role: "user",
        content: [
          {
            type: "text",
            text: input,
          },
        ],
      },
    ];
    setChat((c) => ({...c, messages: messagesRef.current}));
    const codeEndpoint = messagesRef.current.length > 1 ? END_POINTS.EDIT_CODE : END_POINTS.CODE;
    fetchChatStream(messagesRef.current, codeEndpoint);
  }, [chat.id]);

  const fetchChatStream = useCallback(async (messages, endpoint = END_POINTS.CODE) => {

    const userdId = userRef.current.id;
    const chatId = chatIdRef.current;

    // Abort any ongoing fetch before starting a new one
    if (currentController) {
      currentController.abort();
    }

    // Create a new AbortController for the new request
    currentController = new AbortController();
    const signal = currentController.signal;
    
    try {
      setLoading(true);

      const lastMessages = messages.length > 7 ? messages.slice(-5) : messages;

      const response = await fetch(`${SERVER_URL}${endpoint}`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'apiKey': API_KEY,
        },
        body: JSON.stringify({
          userId: userdId,
          chat: {
            id: chatId,
            messages: lastMessages,
          },
        }),
        signal,
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let wholeMessage = "";

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value);

        wholeMessage += chunk;

        setChat((c) => {
          const m = c.messages;
          const lastMessage = m[m.length - 1];

          if (lastMessage?.role === "assistant") {
            // Create a new array reference with an updated last message
            return ({
              ...c,
              messages: [
                ...m.slice(0, -1), // Keep all messages except the last one
                // Update last message with the new chunk
                {
                  ...lastMessage,
                  content: [{
                    type: "text",
                    text: wholeMessage,
                  }] 
                },
              ],
            });
          } else {
            // If last message isn’t from 'assistant', add a new one
            return ({
              ...c,
              messages: [
                ...m,
                {
                  role: "assistant",
                  content: [{
                    type: "text",
                    text: wholeMessage,
                  }]
                },
              ]
            });
          }
        });
      }

      // Update code
      const mcCode = extractFirstCodeBlock(wholeMessage);
      if (mcCode) {
        setCode(mcCode);
      }
    } catch (error) {
      console.error(error);
      // TODO: show error message
    } finally {
      if (currentController) {
        currentController.abort();
      }
      setLoading(false);
    }
  }, []);

  const value = useMemo(
    () => ({
      messages: chat.messages,
      loading,
      setLoading,
      sendMessageWithImage,
      onCreateNewChat,
      sendMessage,
      sendMessageWithImage,
    }),
    [chat, loading]
  );

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};
