import React, { useContext } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import App from "../App";
import SignupPage from "../pages/auth/signup";
import LoginPage from "../pages/auth/login";
import LandingPage from "../pages/landing/LandingPage";
import SubscriptionPage from "../pages/subscription";
import TutorialsPage from "../pages/tutorials";
import { AuthContext } from "../context/AuthContext";

const MainRouter = () => {

  const { user } = useContext(AuthContext);

  if (user?.id) {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/subscription" element={<SubscriptionPage />} />
          <Route path="/tutorials" element={<TutorialsPage />} />
        </Routes>
      </Router>
    );
  }

  return (
    <Router>
      <Routes>
        <Route path="/signin" element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/" element={<LandingPage />} />
      </Routes>
    </Router>
  );
};

export default MainRouter;
