import React, { useContext, useRef, useCallback } from "react";
import {
  LockKeyhole,
  PanelBottomDashed,
  Compass,
  Minus,
  Map,
  MessageSquare,
  RectangleEllipsis,
  CirclePause,
  LayoutList,
  Image,
} from "lucide-react";
import { Translation } from "react-i18next";
import { CodeContext } from "../../App";
import { useDropzone } from "react-dropzone";
import CustomButton from "./Button";
import { useTranslation } from "react-i18next";

const ICON_SIZE = 16;

// notifications screen d
// other examples
// add a chat screne option
// dfine dfualt values in the srver, like colors, borde radus, etc.

const buttons = [
  {
    icon: <Image size={ICON_SIZE} />,
    key: "imageToCode",
    imagePicker: true,
  },
  {
    icon: <LockKeyhole size={ICON_SIZE} />,
    key: "loginScreen",
  },
  // {
  //   icon: <Compass size={ICON_SIZE} />,
  //   key: "socialFeed",
  // },
  {
    icon: <PanelBottomDashed size={ICON_SIZE} />,
    key: "bottomTabNavigator",
  },
  {
    icon: <Minus size={ICON_SIZE} />,
    key: "progressBar",
  },
  {
    icon: <Map size={ICON_SIZE} />,
    key: "mapScreen",
  },
  // {
  //   icon: <MessageSquare size={ICON_SIZE} />,
  //   key: "chatScreen",
  // },
  {
    icon: <LayoutList size={ICON_SIZE} />,
    key: "todoApp",
  },
  // {
  //   icon: <RectangleEllipsis size={ICON_SIZE} />,
  //   label: "Animated pass code",
  //   prompt:
  //     "Create a animated pass code screen with a 4 digit code, the correct code should be 6666",
  // },
  // {
  //   icon: <CirclePause size={ICON_SIZE} />,
  //   key: "audioPlayer",
  // },
];

export default function ButtonGroup({ uploadFile}) {
  const { sendMessage } = useContext(CodeContext);

  const { t } = useTranslation();

  const inputRef = useRef(null);

  const onDrop = useCallback((acceptedFiles) => {
     // Check size must be less than 3MB
     const file = acceptedFiles[0];

     if (file.size > 3 * 1024 * 1024) {
       alert("File size must be less than 3MB");
       return;
     }
 
     // Check type must be image file, png or jpeg
     if (!file.type.includes("image")) {
       alert("File type must be image");
       return;
     }
 
     uploadFile(file);
  }, []);

  const {  getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: true, // Disables the default click behavior
    noKeyboard: true, // Disables the default keyboard behavior
    accept: {
      "image/*": [".jpeg", ".png"],
    },
    multiple: false,
  });

  const handleButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "8px",
        justifyContent: "center",
        padding: "16px",
        maxWidth: "600px",
        margin: "0 auto",
      }}
    >
      {buttons.map((button, index) =>
        button.imagePicker ? (
          <div {...getRootProps()} key={index} style={{ textAlign: "center" }}>
            {/* Hidden file input */}
            <input
              {...getInputProps()}
              ref={inputRef}
              style={{ display: "none" }}
            />
            <CustomButton
              label={t(`chat.suggestions.`+button.key+`.label`)}
              icon={button.icon}
              onClick={handleButtonClick}
            />
          </div>
        ) : (
          <CustomButton
            key={index}
            label={t(`chat.suggestions.`+button.key+`.label`)}
            icon={button.icon}
            onClick={() => {
              sendMessage(t(`chat.suggestions.${button.key}.prompt`))
            }}
          />
        )
      )}
    </div>
  );
}
