import { SERVER_URL, END_POINTS, API_KEY } from "../constants";

export const fetchUserData = async (email) => {
  // Fetch user details using the token (replace with your own logic)
  const response = await fetch(`${SERVER_URL}${END_POINTS.GET_USER}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'apiKey': API_KEY,
    },
    body: JSON.stringify({
      email,
    }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const { user, error } = await response.json();

  if (error) {
    throw new Error(error);
  }

  return user;
};

export const getBillingPortal = async (userId) => {
  try {
    const response = await fetch(
      `${SERVER_URL}${END_POINTS.GET_BILLING_PORTAL}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'apiKey': API_KEY,
        },
        body: JSON.stringify({
          userId,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    return data.url;
  } catch (error) {}
};
