import React from "react";

const Switch = ({ checked, onCheckedChange }) => (
  <label className="relative inline-flex items-center cursor-pointer">
    <input
      type="checkbox"
      style={{ display: "none" }} // Equivalent to "sr-only" (visually hidden)
      checked={checked}
      onChange={onCheckedChange}
    />
    <div
      style={{
        width: "44px", // Equivalent to "w-11"
        height: "24px", // Equivalent to "h-6"
        backgroundColor: checked ? "#2563eb" : "#e5e7eb", // Checked: "bg-blue-600", Unchecked: "bg-gray-200"
        borderRadius: "9999px", // Equivalent to "rounded-full"
        position: "relative", // For the absolute positioning of the inner circle
        transition: "background-color 0.2s ease-in-out",
      }}
    >
      <div
        style={{
          content: "''", // Empty content
          position: "absolute", // To position the circle inside
          top: "2px", // Equivalent to "after:top-[2px]"
          left: checked ? "22px" : "2px", // Moves to the right when checked
          backgroundColor: "#fff", // Equivalent to "after:bg-white"
          borderColor: checked ? "#fff" : "#d1d5db", // Checked: "after:border-white", Unchecked: "after:border-gray-300"
          borderWidth: "1px", // Equivalent to "after:border"
          borderRadius: "9999px", // Equivalent to "after:rounded-full"
          width: "20px", // Equivalent to "after:w-5"
          height: "20px", // Equivalent to "after:h-5"
          transition: "left 0.2s ease-in-out", // Equivalent to "after:transition-all"
        }}
      ></div>
    </div>
  </label>
);

export default Switch;
