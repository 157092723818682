import React from 'react';
import { getBillingPortal } from '../../api';
import { useTranslation } from 'react-i18next';

const Button = ({ children, onClick }) => (
    <button
        onClick={onClick}
        className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    >
        {children}
    </button>
)

const Progress = ({ value, max }) => (
    <div className="w-full bg-gray-200 rounded-full h-2.5">
        <div
            className="bg-blue-600 h-2.5 rounded-full"
            style={{ width: `${(value / max) * 100}%` }}
        ></div>
    </div>
)

const SubscriptionInfo = ({ user }) => {

    const { subscription } = user;
    const { credits, customer } = subscription;
    const { usedCredits, maxPro, maxFree } = credits;

    const realCredits = Math.round(usedCredits * 1000);
    const maxProCredits = maxPro * 1000;
    const maxFreeCredits = maxFree * 1000;
    const isPro = subscription.plan === 'pro';

    const { t } = useTranslation();

    const handleManageBilling = () => {
        // Add your logic here
        getBillingPortal(user.id).then((url) => {
            window.open(url);
        })
    }

  const curr = Math.min(realCredits, isPro ? maxProCredits : maxFreeCredits);

    return (
        <div className="w-full max-w-3xl mx-auto p-6 bg-white rounded-lg shadow" style={{ 
            marginBottom: 60, 
            maxWidth: '100vw', // Ensure max width covers the entire screen
        }}>
            <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-semibold text-gray-800">{t('pages.subscription.subscriptionDetails')}</h2>
                {
                    customer && <Button onClick={handleManageBilling}>{t('pages.subscription.managebBillingInfo')}</Button>
                }
            </div>

            <div className="mb-6">
                <div className="flex justify-between items-center mb-2">
                    <span className="text-sm font-medium text-gray-600">{t('pages.subscription.currentPlan')}</span>
                    <span className="text-sm font-semibold text-gray-800">{isPro ? t('pages.subscription.pro') : t('pages.subscription.free')}</span>
                </div>
                <div className="h-px bg-gray-200"></div>
            </div>

            <div>
                <div className="flex justify-between items-center mb-2">
                    <span className="text-sm font-medium text-gray-600">{t('pages.subscription.usage')}</span>
                    <span className="text-sm text-gray-400">{`${curr}/${isPro ? maxProCredits : maxFreeCredits} ${t('pages.subscription.credits')}`}</span>
                </div>
                <Progress value={curr} max={isPro ? maxProCredits : maxFreeCredits} />
            </div>
        </div>
    )
}


export default SubscriptionInfo