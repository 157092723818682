import React, { useEffect } from "react";
import SplitLayout from "../../components/layout/splitLayout";
import Pricing from "../../components/princing";
import MCSideBar from "../../components/sideBar/";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { fetchUserData } from "../../api";
import { updateUser } from "../../redux/userSlice";
import { useDispatch } from "react-redux";

const SubscriptionPage = () => {
  const { user, setUser } = useContext(AuthContext);

  const dispatch = useDispatch();

  useEffect(() => {
    fetchUserData(user.email)
    .then((data) => {
      if (data?.id) {
        dispatch(updateUser(data));
        setUser(data);
      }
    })
    .catch((error) => {});
  }, []);

  useEffect(() => {
    const handleVisibilityChange = async () => {
        if (document.visibilityState === 'visible') {
            try {
              fetchUserData(user.email)
              .then((data) => {
                if (data?.id) {
                  dispatch(updateUser(data));
                  setUser(data);
                }
              })
              .catch((error) => {});
            } catch (error) {
            }
        }
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup the event listener on component unmount
    return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
}, []);

  return (
    <SplitLayout
      sideBar={<MCSideBar onCreateNewChat={() => {}} />}
      leftComponent={<Pricing user={user} />}
      rightComponent={null}
    />
  );
};

export default SubscriptionPage;
