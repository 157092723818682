import React, { useMemo, useCallback, useState, useRef, useEffect, useContext } from "react";
import InfiniteScroll from "react-infinite-scroller";
import ChatInput from "../ChatInput";
import UserMessageBubble from "./UserMessageBubble";
import EmptyChat from "./EmptyChat";
import { ChatContext } from "../../context/ChatContext";
import SystemMessage from "./SystemMessage";

const screenHeight = window.innerHeight;

const McQueenChat = () => {
  const { messages } = useContext(ChatContext);

  const [chatHeight, setChatHeight] = useState(screenHeight - 70);

  const listRef = useRef(null);

  useEffect(() => {
    scrollToEnd();
  }, [messages]);

  const loadMore = useCallback(() => { }, []);

  const scrollToEnd = useCallback(() => {
    if (listRef.current) {
      listRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const onChatInputLayout = useCallback(({ height }) => {
    if (height) {
      const h = screenHeight - height;
      setChatHeight(h);
    }
  }, []);

  const last8Messages = useMemo(() => messages.slice(-9), [messages]);

  const chatStyleListStyle = useMemo(
    () => ({
      height: chatHeight,
      overflow: "auto",
      width: "100%",
    }),
    [chatHeight]
  );

  return (
    <div style={styles.container}>
      {last8Messages.length ? (
        <div style={chatStyleListStyle}>
          <InfiniteScroll
            pageStart={0}
            loadMore={loadMore}
            hasMore={false}
            useWindow={false}
            style={styles.infiniteScroll}
          >
            {last8Messages.map((message, index) => (
              <div key={index} style={styles.chatBubble}>
                {message.role === "user" ? (
                  <UserMessageBubble message={message} />
                ) : (
                  <SystemMessage message={message} />
                )}
              </div>
            ))}

            <div ref={listRef}></div>
          </InfiniteScroll>
        </div>
      ) : (
        <EmptyChat height={chatHeight} />
      )}

      <ChatInput onLayout={onChatInputLayout} />
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100vh",
    alignItems: "center",
    maxWidth: 800,
    margin: "auto",
  },
  infiniteScroll: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  chatBubble: {
    padding: 10
  }
};

McQueenChat.displayName = "McQueenChat";

export default React.memo(McQueenChat);
