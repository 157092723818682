import { createContext, useMemo, useState } from "react";
import useSnack from "../hooks/useSnack";
import { INTIAL_CODE } from "../constants";

export const ExpoContext = createContext(null);

export const ExpoProvider = ({ children }) => {
  const [code, setCode] = useState(INTIAL_CODE);
  const [refreshCode, setRefreshCode] = useState(false);
  const [showPreview, setShowPreview] = useState(true);

  const { snackUrl, connectedDevices} = useSnack(code, refreshCode);

  const value = useMemo(
    () => ({
      snackUrl,
      code,
      showPreview,
      connectedDevices,
      setCode,
      setRefreshCode,
      setShowPreview,
    }),
    [snackUrl, code, showPreview, connectedDevices]
  );

  return <ExpoContext.Provider value={value}>{children}</ExpoContext.Provider>;
};