import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeHighlight from "rehype-highlight";
import "./code-styles.css";

const SystemMessage = ({ message }) => {

  return (
    <ReactMarkdown
      className={"markdown"}
      rehypePlugins={[rehypeHighlight]}
      components={{
        code({ className, children, ...rest }) {
          return (
            <pre style={styles.pre}>
              {/* <button style={styles.copyButton} onClick={() => handleCopy(children)}>
                  Copiar código
                </button> */}
              <code {...rest} style={styles.code}>
                {children}
              </code>
            </pre>
          );
        },
      }}
    >
      {message.content[0].text}
    </ReactMarkdown>
  );
};

const styles = {
  pre: {
    backgroundColor: "#f5f5f5", // Light gray background to match ChatGPT style
    color: "#333", // Dark gray for default text
    padding: "16px",
    borderRadius: "8px",
    overflowX: "auto",
    fontFamily: 'Consolas, "Courier New", monospace',
    fontSize: "14px",
  },
  code: {
    lineHeight: "1.5",
    whiteSpace: "pre-wrap",
  },
  copyButton: {
    position: "absolute",
    top: "8px",
    right: "8px",
    padding: "4px 8px",
    backgroundColor: "#e0e0e0",
    color: "#333",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "12px",
  },
};

SystemMessage.displayName = "SystemMessage";

export default React.memo(
  SystemMessage,
  (prev, next) => prev.message === next.message
);
