import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./LandingPage.css";

const LandingPage = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const onSignUp = () => {
    navigate("/signup");
  }

  const onSignIn = () => {
    navigate("/signin");
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-900 to-blue-600 text-white">
      <header className="container mx-auto px-4 py-6 flex justify-between items-center">
        <div className="text-2xl font-bold">McQueen</div>
        <nav className="hidden md:flex space-x-6">
          {/* <Link to="#" className="hover:text-blue-300">PRODUCT</Link>
          <Link to="#" className="hover:text-blue-300">PRICING</Link>
          <Link to="#" className="hover:text-blue-300">DOCS</Link>
          <Link to="#" className="hover:text-blue-300">BLOG</Link>
          <Link to="#" className="hover:text-blue-300">SANDBOX</Link> */}
        </nav>
        <div className="flex items-center space-x-4">
          <Link to="/signin" className="hover:text-blue-300">{t('landing.signin')}</Link>
          <button className="bg-white text-blue-900 hover:bg-blue-100 py-2 px-4 rounded" onClick={onSignUp}>
            {t('landing.startForFree')}
          </button>
        </div>
      </header>

      <main className="container mx-auto px-4 py-20 text-center">
        <h1 className="text-6xl md:text-7xl font-bold mb-6 leading-tight">
          {t('landing.header')}
        </h1>
        <p className="text-xl mb-10">
          {t('landing.subtitle')}
        </p>
        <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-4">
          <button className="bg-white text-blue-900 hover:bg-blue-100 py-2 px-6 rounded" onClick={onSignUp}>
          {t('landing.tryMcqueen')}
          </button>
          {/* <button className="border-white text-white hover:bg-blue-800 py-2 px-6 rounded border">
            GET A DEMO
          </button> */}
        </div>
      </main>

      <div className="container mx-auto px-4 mt-20 pb-20">
          <video 
            className="video-container" 
            controls
            poster="https://lhuvfrztjfdimbuetskm.supabase.co/storage/v1/object/public/landing/demo_1_cover.jpg?t=2024-11-17T09%3A11%3A04.957Z"
          >
            <source 
              src="https://lhuvfrztjfdimbuetskm.supabase.co/storage/v1/object/public/landing/demo_1.mp4?t=2024-11-17T09%3A06%3A56.436Z" 
              type="video/mp4" 
            />
            Your browser does not support the video tag.
          </video>
        </div>
    </div>
  );
};

export default LandingPage;
