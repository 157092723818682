import React, { useCallback, useContext, useState } from "react";
import { CodeContext } from "../App";
import { debounce } from "lodash";
import { ColorRing } from "react-loader-spinner";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../context/AuthContext";

const BUTTON_SIZE = 30;

const ChatInput = ({ onLayout }) => {
  const { sendMessage, loading, messages } =
    useContext(CodeContext);
  const { user } = useContext(AuthContext);

  const { subscription } = user;
  const { active, requests } = subscription || {};
  const {
    currentRequests,
    maxFree: maxFreeRequests,
    maxPro: maxProRequests,
  } = requests || {};

  const limitReached =
    currentRequests >= (active ? maxProRequests : maxFreeRequests);

  const label =
    limitReached && active ? "Limit reached" : "Limit reached, upgrade to Pro.";

  const { t } = useTranslation();

  const [input, setInput] = useState("");

  const disabled = loading || input.length < 5 || limitReached;

  useEffect(() => {
    _onLayout();
  }, []);

  const _onLayout = useCallback(() => {
    const height = document.getElementById("mc-chat-input").offsetHeight;
    onLayout({
      height,
    });
  }, []);

  const onSend = useCallback(() => {
    if (disabled) return;
    sendMessage(document.getElementById("message").value);
    document.getElementById("message").value = "";
    setInput("");
  }, [disabled]);

  const onChange = useCallback(
    debounce((e) => {
      setInput(e.target.value);
    }, 50),
    []
  );

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent default Tab behavior
      onSend(); // Send message when Tab is pressed
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minWidth: 800,
      }}
    >
      {limitReached && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#f9fafb",
            padding: 4,
            fontSize: 15,
            borderTopLeftRadius: 12,
            borderTopRightRadius: 12,
            cursor: "unset",
          }}
        >
          <span>{label}</span>
        </div>
      )}

      <div
        id="mc-chat-input"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
          maxWidth: 800,
          backgroundColor: "#f9fafb",
          borderRadius: 12,
          justifyContent: "space-between",
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "#e5e5e5",
          marginBottom: 10,
        }}
      >
        {/* <ImageUpload /> */}
        <textarea
          id="message"
          onChange={onChange}
          onKeyDown={onKeyDown}
          style={{
            border: "none",
            minHeight: 50,
            width: 80,
            resize: "none",
            borderRadius: 12,
            padding: 10,
            fontSize: 16,
            fontFamily: '"Fira code", "Fira Mono", monospace',
            width: "90%",
            backgroundColor: "#f9fafb",
          }}
          placeholder={
            messages?.length ? t("chat.followUp") : t("chat.startBuilding")
          }
        />
        {loading ? (
          <ColorRing
            visible={true}
            height="50"
            width="50"
            ariaLabel="color-ring-loading"
            wrapperStyle={{
              marginRight: BUTTON_SIZE / 2,
            }}
            wrapperClass="color-ring-wrapper"
            colors={["#001c40"]}
          />
        ) : (
          <button
            onClick={onSend}
            disabled={disabled}
            style={{
              width: BUTTON_SIZE,
              height: BUTTON_SIZE,
              borderRadius: BUTTON_SIZE,
              border: "none",
              justifyContent: "center",
              alignItems: "center",
              marginRight: BUTTON_SIZE / 2,
              boxDecoration: "none",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              fill={disabled ? "gray" : "#1e3050"}
              height={BUTTON_SIZE}
              width={BUTTON_SIZE}
            >
              <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM385 231c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-71-71V376c0 13.3-10.7 24-24 24s-24-10.7-24-24V193.9l-71 71c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9L239 119c9.4-9.4 24.6-9.4 33.9 0L385 231z" />
            </svg>
          </button>
        )}
      </div>
    </div>
  );
};

export default React.memo(ChatInput);
