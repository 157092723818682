import { useCallback, useEffect, useRef, useState, version } from "react";
import { Snack } from "snack-sdk";
import { DEPENDENCIES } from "./dependencies";

const useSnack = (code, refreshCode) => {
  const [snackUrl, setSnackUrl] = useState(null);
  const [webPreviewURL, setWebPreviewURL] = useState(null);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [connectedDevices, setConnectedDevices] = useState([]);

  const webPreviewRef = useRef(null);
  const prevCodeRef = useRef(code);

  const snack = useRef(
    new Snack({
      sdkVersion: '52.0.0',
      dependencies: DEPENDENCIES,
      files: {
        "App.tsx": {
          type: "CODE",
          contents: code,
        },
      },
      name: "McQween Snack",
      description: "Your react native code made by McQween",
      //webPreviewRef: webPreviewRef,
      //online: true,
      codeChangesDelay: 300,
    })
  ).current;

  useEffect(() => {
    snack.addStateListener((state, prevState) => {
      if (state.connectedClients !== prevState.connectedClients) {
        for (const key in state.connectedClients) {
          if (!prevState.connectedClients[key]) {
            setConnectedDevices((prev) => {
              const newDevice = state.connectedClients[key];
              if (!prev.some((device) => device.id === newDevice.id)) {
                  return [...prev, newDevice];
              }
              return prev;
          });
          }
        }
      }
    });
  }, []);

  useEffect(() => {
    (async () => {
      const { dependencies, files } = await snack.getStateAsync();

      //     // Find dependencies that are not installed
      // const missingDependencies = Object.entries(dependencies)
      // .filter(([packageName, packageInfo]) => !packageInfo.version)
      // .reduce((acc, [packageName, packageInfo]) => {
      //   acc[packageName] = packageInfo.requestedVersion || '*';
      //   return acc;
      // }, {});

      // console.log({
      //   missingDependencies
      // })

      // console.log(JSON.stringify(dependencies, null, 2));

      // const missingDependencies = dependencies.filter(dep => !dep.installed);
      // if (missingDependencies.length > 0) {
      //   // Install missing dependencies
      //   await snack.addDependencies(missingDependencies);
      // }

      // Make the Snack available online
      snack.setOnline(true);

      const { webPreviewURL, url } = snack.getState();
      setSnackUrl(url);
      setWebPreviewURL(webPreviewURL);
    })();

    // Stop Snack when done
    return () => {
      snack.setOnline(false);
    };
  }, []);

  useEffect(() => {
    (async () => {
      // const state = await snack.getStateAsync();
      // console.log(state.missingDependencies);

      snack.updateFiles({
        "App.tsx": {
          type: "CODE",
          contents: code,
        },
      });

      prevCodeRef.current = code;
      setShouldUpdate(false);
    })();
  }, [refreshCode]);

  useEffect(() => {
    if (code !== prevCodeRef.current) {
      //setShouldUpdate(true);
      updateCode(code);
    }
  }, [code]);

  const updateCode = useCallback(async (newCode) => {
    // // Parse the code to find imported modules
    // const importedModules = getImportedModules(newCode);

    // // Get the current dependencies from Snack
    // const { dependencies } = await snack.getStateAsync();

    // console.log({
    //   importedModules,
    //   dependencies,
    // });

    // // Identify missing dependencies
    // const missingDependencies = {};
    // importedModules.forEach((moduleName) => {
    //   if (!dependencies[moduleName]) {
    //     missingDependencies[moduleName] = {
    //       version: "*",
    //     }
    //   }
    // });

    // // Install missing dependencies if any
    // if (Object.keys(missingDependencies).length > 0) {
    //   console.log("Installing missing dependencies:", missingDependencies);
    //   snack.updateDependencies(dependencies);
    // }

    snack.updateFiles({
      "App.tsx": {
        type: "CODE",
        contents: newCode,
      },
    }); 

    prevCodeRef.current = newCode;
  }, []);

  return {
    snackUrl,
    // webPreviewURL,
    // webPreviewRef,
    connectedDevices,
    shouldUpdate,
  };
};

export default useSnack;

// // Function to extract imported modules from the code
// function getImportedModules(code) {
//   const modules = [];

//   // Regex to match ES6 import statements
//   const importRegex = /import\s+(?:[^'"]*\s+from\s+)?['"]([^'"]+)['"]/g;
//   let match;
//   while ((match = importRegex.exec(code)) !== null) {
//     modules.push(match[1]);
//   }

//   // Regex to match CommonJS require statements
//   const requireRegex = /require\(['"]([^'"]+)['"]\)/g;
//   while ((match = requireRegex.exec(code)) !== null) {
//     modules.push(match[1]);
//   }

//   // Filter out relative paths and remove duplicates
//   const packageModules = [...new Set(modules)].filter((moduleName) => {
//     return !moduleName.startsWith(".") && !moduleName.startsWith("/");
//   });

//   return packageModules;
// }
