import React from 'react';

const CustomButton = ({ label, icon, onClick }) => {
  const handleMouseEnter = (e) => {
    e.currentTarget.style.backgroundColor = "#f8f8f8";
    e.currentTarget.style.boxShadow = "0 2px 4px rgba(0,0,0,0.1)";
  };

  const handleMouseLeave = (e) => {
    e.currentTarget.style.backgroundColor = "white";
    e.currentTarget.style.boxShadow = "none";
  };

  return (
    <button
      style={styles.button}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
    >
      <span style={styles.iconWrapper}>
        {icon}
      </span>
      {label}
    </button>
  );
};

const styles = {
  button: {
    display: "flex",
    alignItems: "center",
    padding: "8px 16px",
    backgroundColor: "white",
    border: "1px solid #c0c0c0",
    borderRadius: "20px",
    cursor: "pointer",
    fontSize: "14px",
    color: "#333",
    transition: "background-color 0.3s, box-shadow 0.3s",
  },
  iconWrapper: {
    marginRight: "8px",
    display: "flex",
    alignItems: "center",
  }
};

export default CustomButton;