import React, { createContext, useMemo, useContext } from "react";
import ExpoPreview from "./components/ExpoPreview";
import McQueenChat from "./components/chat";
import SplitLayout from "./components/layout/splitLayout";
import MCSideBar from "./components/sideBar/";
import { ChatContext } from "./context/ChatContext";
import { ExpoContext } from "./context/expoProvider"

export const CodeContext = createContext();

export const SIDE_BAR_WIDTH = 40;
export const PADDING = 20;
export const PREVIEW_WIDTH = 200;
export const MAIN_CONTAINTER_WIDTH =
  window.innerWidth - (SIDE_BAR_WIDTH + PADDING * 2);
export const CODE_EDITOR_WIDTH = MAIN_CONTAINTER_WIDTH - PREVIEW_WIDTH;
export const HEADER_HEIGHT = 20;
export const CODE_HEADER_HEIGHT = 40;
export const MAIN_CONTAINER_HEIGHT =
  window.innerHeight - HEADER_HEIGHT - PADDING;

const App = () => {
  const { messages, onCreateNewChat, loading, sendMessage } =
    useContext(ChatContext);
  const { showPreview } = useContext(ExpoContext);

  const value = useMemo(
    () => ({
      loading,
      sendMessage,
      messages,
    }),
    [loading, messages, sendMessage]
  );

  return (
    <CodeContext.Provider value={value}>
      <SplitLayout
        showControls={true}
        sideBar={<MCSideBar onCreateNewChat={onCreateNewChat} />}
        leftComponent={<McQueenChat />}
        rightComponent={showPreview ? <ExpoPreview width={PREVIEW_WIDTH} /> : null}
      />
    </CodeContext.Provider>
  );
};

export default App;
