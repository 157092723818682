import React, { createContext, useState, useEffect } from "react";
import { useDispatch, } from "react-redux";
import { updateUser } from "../redux/userSlice";
import { fetchUserData } from "../api";

const MA_QWEEN_TOKEN_KEY = "ma-qween-token";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({
    id: null,
    email: null,
    photoURL: null,
    displayName: null,
    created_at: null,
    subscription: null,
    pricing: null,
  });

  useEffect(() => {
    // Check if there's a token in localStorage
    const email = localStorage.getItem(MA_QWEEN_TOKEN_KEY);

    if (email) {
      // Fetch user details using the token
      (async ()=>{
        try {
          const data = await fetchUserData(email);
          if (data?.id) {
            dispatch(updateUser(data));
            setUser(data);
            setLoading(false);
          }else {
            setLoading(false);
          }
        } catch (error) {
          
        }
      })()
    } else {
      setLoading(false);
    }
  }, []);

  const login = async (email) => {
    localStorage.setItem(MA_QWEEN_TOKEN_KEY, email);
    const data = await fetchUserData(email);

    if (data?.id) {
      dispatch(updateUser(data));
      setUser(data);
      setLoading(false);
    }

  };

  const signup = async (email) => {
   localStorage.setItem(MA_QWEEN_TOKEN_KEY, email);
   const data = await fetchUserData(email);

   if (data?.id) {
     dispatch(updateUser(data));
     setUser(data);
     setLoading(false);
   }
  };

  const logout = async () => {
    localStorage.removeItem(MA_QWEEN_TOKEN_KEY);
    dispatch(updateUser(null));
    setUser(null);

  };

  return (
    <AuthContext.Provider value={{ login, logout, signup, setUser, user }}>
      {loading ? <div>Loading...</div> : children}
    </AuthContext.Provider>
  );
};
