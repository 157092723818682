import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { sendToVercelAnalytics } from "./vitals";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { ExpoProvider } from "./context/expoProvider";
import { AuthProvider } from "./context/AuthContext";
import { ChatProvider } from "./context/ChatContext";
import MainRouter from "./router/MainRouter";
import "./i18n";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider>
        <ExpoProvider>
          <ChatProvider>
            <MainRouter />
          </ChatProvider>
        </ExpoProvider>
      </AuthProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals(sendToVercelAnalytics);
