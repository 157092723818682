import React, { useCallback, useContext } from "react";
import { Plus, TvMinimalPlay, LogOut, Wallet } from "lucide-react";
import { useNavigate, useLocation } from "react-router-dom";
// import DropdownMenu from "../dropdownMenu";
import TooltipButton from "../tooltip";
import { AuthContext } from "../../context/AuthContext";
import { ChatContext } from "../../context/ChatContext";

export const SIDE_BAR_WIDTH = 56;

const MCSideBar = () => {
  const { onCreateNewChat } = useContext(ChatContext);
  const { logout } = useContext(AuthContext);

  const navigate = useNavigate();
  const location = useLocation();

  const goToSubscription = useCallback(() => {
    navigate("/subscription");
  }, [navigate]);

  const goToTutorials = useCallback(() => {
    navigate("/tutorials");
  }, [navigate]);

  const onPlusClick = useCallback(() => {
    if (location.pathname !== "/") {
      navigate("/");
    } else {
      onCreateNewChat();
    }
  }, [onCreateNewChat, location]);

  const onSingOut = useCallback(() => {
    logout();
    navigate("/");
  }, []);

  const onGoToCommunity = useCallback(() => {
    window.open("https://discord.gg/aavggRMJ", "_blank");
  }, []);

  return (
    <div style={styles.sidebarStyle}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ ...styles.iconContainerStyle, ...styles.logoStyle }}>
          <svg
            width="256px"
            height="256px"
            viewBox="-25.6 -25.6 307.20 307.20"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid"
            fill="#000000"
            transform="rotate(0)"
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              strokeLinecap="round"
              strokeLinejoin="round"
              stroke="#CCCCCC"
              strokeWidth="4.096"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <g>
                {" "}
                <path
                  d="M127.962816,0 C198.911815,0.558586285 255.754376,55.4725719 255.999327,129.30333 C256.222426,196.981097 200.939466,257.457031 124.744539,255.972592 C55.244106,254.617978 -1.52329281,198.006026 0.0311827848,124.08815 C1.40287938,58.8018828 54.6684376,0.674744901 127.962816,0 Z M101.003768,147.357453 C105.554452,147.357453 110.106845,147.367703 114.65753,147.352329 C116.833796,147.345496 118.196951,147.837462 117.689611,150.565481 C116.574147,156.564732 115.660252,162.599855 114.679737,168.621313 C112.832587,179.971832 110.991701,191.32349 109.157078,202.676286 C108.981132,203.757586 108.415712,204.914048 109.809616,205.660538 C112.182326,206.929741 118.670127,205.31377 120.011075,203.0743 C137.018063,174.694017 154.01651,146.30861 171.006416,117.918077 C174.535588,112.012778 172.683883,108.820124 165.760488,108.768878 C157.794512,108.711938 149.828537,108.711938 141.862561,108.768878 C139.389066,108.782544 137.708182,108.456275 138.304349,105.219207 C140.080893,95.5899998 141.703697,85.9334607 143.331626,76.2769217 C144.604815,68.7175012 145.824481,61.1489702 146.990622,53.5713287 C147.16486,52.4404904 147.853271,51.0414624 146.011815,50.3701339 C142.079426,49.2279869 137.854573,50.6944931 135.475545,54.0274221 C122.392896,75.8151342 109.320497,97.6091098 96.2583467,119.409349 C92.3157866,125.985976 88.2553597,132.494275 84.4989951,139.176812 C81.6838569,144.181882 83.5321454,147.282292 89.0565125,147.34208 C93.0383616,147.384785 97.0219188,147.350621 101.005476,147.359162 L101.003768,147.357453 Z"
                  fill="#1D5FE6"
                ></path>{" "}
              </g>{" "}
            </g>
          </svg>
        </div>
        <TooltipButton
          white={true}
          label={location.pathname !== "/" ? "Chat" : "New chat"}
          onClick={onPlusClick}
        >
          <Plus style={styles.iconPlusStyle} />
        </TooltipButton>
        <TooltipButton label={"Subscription"} onClick={goToSubscription}>
          <Wallet style={styles.iconStyle} />
        </TooltipButton>
        <TooltipButton label={"Tutorials"} onClick={goToTutorials}>
          <TvMinimalPlay style={styles.iconStyle} />
        </TooltipButton>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <TooltipButton label={"Community"} onClick={onGoToCommunity}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
            fill="#5865f2"
            width={22}
            height={22}
          >
            <path d="M524.5 69.8a1.5 1.5 0 0 0 -.8-.7A485.1 485.1 0 0 0 404.1 32a1.8 1.8 0 0 0 -1.9 .9 337.5 337.5 0 0 0 -14.9 30.6 447.8 447.8 0 0 0 -134.4 0 309.5 309.5 0 0 0 -15.1-30.6 1.9 1.9 0 0 0 -1.9-.9A483.7 483.7 0 0 0 116.1 69.1a1.7 1.7 0 0 0 -.8 .7C39.1 183.7 18.2 294.7 28.4 404.4a2 2 0 0 0 .8 1.4A487.7 487.7 0 0 0 176 479.9a1.9 1.9 0 0 0 2.1-.7A348.2 348.2 0 0 0 208.1 430.4a1.9 1.9 0 0 0 -1-2.6 321.2 321.2 0 0 1 -45.9-21.9 1.9 1.9 0 0 1 -.2-3.1c3.1-2.3 6.2-4.7 9.1-7.1a1.8 1.8 0 0 1 1.9-.3c96.2 43.9 200.4 43.9 295.5 0a1.8 1.8 0 0 1 1.9 .2c2.9 2.4 6 4.9 9.1 7.2a1.9 1.9 0 0 1 -.2 3.1 301.4 301.4 0 0 1 -45.9 21.8 1.9 1.9 0 0 0 -1 2.6 391.1 391.1 0 0 0 30 48.8 1.9 1.9 0 0 0 2.1 .7A486 486 0 0 0 610.7 405.7a1.9 1.9 0 0 0 .8-1.4C623.7 277.6 590.9 167.5 524.5 69.8zM222.5 337.6c-29 0-52.8-26.6-52.8-59.2S193.1 219.1 222.5 219.1c29.7 0 53.3 26.8 52.8 59.2C275.3 311 251.9 337.6 222.5 337.6zm195.4 0c-29 0-52.8-26.6-52.8-59.2S388.4 219.1 417.9 219.1c29.7 0 53.3 26.8 52.8 59.2C470.7 311 447.5 337.6 417.9 337.6z" />
          </svg>
        </TooltipButton>
        <TooltipButton label={"Sign out"} onClick={onSingOut}>
          <LogOut style={styles.iconStyle} />
        </TooltipButton>
      </div>
    </div>
  );
};

const styles = {
  sidebarStyle: {
    width: `${SIDE_BAR_WIDTH}px`,
    backgroundColor: "#f3f4f6",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "16px 0",
    borderRight: "1px solid #e5e7eb",
    justifyContent: "space-between",
  },
  logoStyle: {
    width: "28px",
    height: "28px",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px",
    fontWeight: "bold",
    borderRadius: "4px",
  },
  iconContainerStyle: {
    marginBottom: "24px",
    cursor: "pointer",
  },
  iconStyle: {
    width: "20px",
    height: "20px",
    color: "#4b5563",
  },
  iconPlusStyle: {
    width: "20px",
    height: "20px",
    color: "#4b5563",
  },
};

MCSideBar.displayName = "MCSideBar";

export default React.memo(MCSideBar);
