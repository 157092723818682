import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: null,
  email: null,
  photoURL: null,
  displayName: null,
  created_at: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser: (state, action) => {
      state = {...action.payload};
    },
    resetUser: (state) => {
      state = initialState;
    },
  },
});

export const { updateUser, resetUser } = userSlice.actions;

export default userSlice.reducer;
