import React, { useState, useCallback, useEffect, useContext,} from "react";
import { SIDE_BAR_WIDTH } from "../../sideBar/index";
import Switch from "../../switch";
import { ExpoContext } from "../../../context/expoProvider";

const SplitLayout = ({ sideBar, rightComponent, leftComponent, showControls = false }) => {

  const { showPreview, setShowPreview,} = useContext(ExpoContext);

  const [isDragging, setIsDragging] = useState(false);
  const [leftWidth, setLeftWidth] = useState(70);

  const handleMouseDown = useCallback(() => {
    setIsDragging(true);
  }, []);

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleMouseMove = useCallback(
    (e) => {
      if (isDragging) {
        const splitContainerWidth = window.innerWidth - SIDE_BAR_WIDTH;
        const newWidth =
          ((e.clientX - SIDE_BAR_WIDTH) / splitContainerWidth) * 100;
        setLeftWidth(Math.min(Math.max(newWidth, 10), 90));
      }
    },
    [isDragging, SIDE_BAR_WIDTH]
  );

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp]);

  const containerStyle = {
    display: "flex",
    height: "100vh",
    width: "100%",
    overflow: "hidden",
  };

  const splitContainerStyle = {
    display: "flex",
    flex: 1,
    overflow: "hidden",
  };

  const leftPaneStyle = {
    width: `${rightComponent ? leftWidth : '100'}%`,
    overflow: "auto",
    display: "flex",
    justifyContent: "center",
  };

  const leftCenterContainerStyle = {
    minWidth: "600px",
    maxWidth: rightComponent ? "800px" : '100%',
    width: "100%",
    padding: "16px",
    paddingTop: "0",
    boxSizing: "border-box",
  };

  const rightPaneStyle = {
    width: `${100 - leftWidth}%`,
    overflow: "auto",
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
  };

  const rightCenterContainerStyle = {
    maxWidth: "400px",
    padding: "16px",
    boxSizing: "border-box",
  };

  const dividerStyle = {
    width: "2px",
    backgroundColor: "#e5e7eb",
    cursor: "col-resize",
  };

  return (
    <div style={containerStyle}>
      {sideBar}
      {
        showControls && 
        <div style={{ position:'absolute', right: 0, top: 0, padding: 20}}>
          <Switch checked={showPreview} onCheckedChange={() => setShowPreview(val => !val)} />
        </div>
      }
      <div style={splitContainerStyle}>
        <div style={leftPaneStyle} aria-label="Left pane">
          <div style={leftCenterContainerStyle}>{leftComponent}</div>
        </div>
        {rightComponent ? (
          <>
            <div
              style={dividerStyle}
              onMouseDown={handleMouseDown}
              role="separator"
              aria-valuenow={leftWidth}
              aria-valuemin={10}
              aria-valuemax={90}
              aria-label="Resize panes"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "ArrowLeft")
                  setLeftWidth((prev) => Math.max(prev - 1, 10));
                if (e.key === "ArrowRight")
                  setLeftWidth((prev) => Math.min(prev + 1, 90));
              }}
            />
            <div style={rightPaneStyle} aria-label="Right pane">
              <div style={rightCenterContainerStyle}>{rightComponent}</div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default SplitLayout;
