export const DEPENDENCIES = Object.freeze({
  "@shopify/react-native-skia":{
    version: "1.5.3", 
  },
  "victory-native": {
    version: "41.9.0",
  },
  "react-native-paper":{
    version: "5.12.5",
  },
  "react-native-drawer-layout":{
    version: "4.0.1",
  },
  "expo-linear-gradient": {
    version: "8.2.1",
  },
  "expo-video": {
    version: "1.1.10",
  },
  "expo-av": {
    version: "14.0.5",
  },
  "expo-blur": {
    version: "13.0.2",
  },
  /*'expo-calendar': {
        version: '12.0.1'
      },*/
  "expo-checkbox": {
    version: "3.0.0",
  },
  "expo-clipboard": {
    version: "6.0.3",
  },
  "expo-crypto": {
    version: "13.0.2",
  },
  "expo-device": {
    version: "6.0.2",
  },
  "expo-file-system": {
    version: "17.0.1",
  },
  "expo-gl": {
    version: "14.0.2",
  },
  "expo-sensors": {
    version: "13.0.8",
  },
  "expo-haptics": {
    version: "13.0.1",
  },
  "expo-image": {
    version: "1.12.12",
  },
  "react-native-reanimated": {
    version: "3.12.0",
  },
  "expo-image-picker": {
    version: "15.0.5",
  },
  /*'expo-local-authentication':{
        version: '14.0.1'
      },*/
  "expo-location": {
    version: "17.0.1",
  },
  /*'lottie-react-native':{
        version: '6.7.2',
      }*/
  "react-native-svg": {
    version: "15.3.0",
  },
  "react-native-gesture-handler": {
    version: "2.16.2",
  },
  "@react-native-community/slider": {
    version: "4.5.2",
  },
  "@gorhom/bottom-sheet": {
    version: "4.6.3",
  },
  "@react-navigation/native": {
    version: "6.1.17",
  },
  "react-native-screens": {
    version: "3.31.1",
  },
  "react-native-safe-area-context": {
    version: "4.10.4",
  },
  "@react-navigation/stack": {
    version: "6.4.0",
  },
  "@react-navigation/native-stack": {
    version: "6.9.26",
  },
  "@react-navigation/drawer": {
    version: "6.6.15",
  },
  "@react-navigation/bottom-tabs": {
    version: "6.5.20",
  },
  "react-native-gifted-chat": {
    version: "2.4.0",
  },
  "react-native-maps": {
    version: "1.14.0",
  },
  "react-native-pager-view": {
    version: "6.3.3",
  },
  "react-native-fast-image": {
    version: "8.6.3",
  },
  "react-native-webview": {
    version: "13.10.4",
  },
  "expo-splash-screen": {
    version: "0.27.5",
  },
  "@react-native-picker/picker": {
    version: "2.7.7",
  },
  "@react-native-community/netinfo": {
    version: "11.3.2",
  },
  "@shopify/flash-list": {
    version: "1.6.4",
  },
  "@react-native-community/datetimepicker": {
    version: "8.1.1",
  },
  "expo-camera": {
    version: "15.0.12",
  },
  "react-native-calendars": {
    version: "1.1305.0",
  },
  "react-native-draggable-flatlist": {
    version: "4.0.1",
  },
  "react-native-permissions": {
    version: "4.1.5",
  },
  // react-native-paper,
});
