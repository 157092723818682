import React, { useCallback, useState, useContext, } from "react";
import SuggestionButton from "./SuggestionButton";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { SERVER_URL, END_POINTS, API_KEY } from "../../constants";
import { ChatContext } from "../../context/ChatContext";
import axios from "axios";
import { v4 } from "uuid";

const ACCEPTED_IMG_TYPE = {
  "image/jpeg": "jpg",
  "image/png": "png",
};

const EmptyChat = ({ height }) => {

  const { sendMessageWithImage } = useContext(ChatContext);
  const { t } = useTranslation();

  const [preview, setPreview] = useState(null);
  const [progress, setProgress] = useState(0);

  const onDrop = useCallback((acceptedFiles) => {
    // Check size must be less than 3MB
    const file = acceptedFiles[0];

    if (file.size > 3 * 1024 * 1024) {
      alert("File size must be less than 3MB");
      return;
    }

    // Check type must be image file, png or jpeg
    if (!file.type.includes("image")) {
      alert("File type must be image");
      return;
    }

    uploadFile(file);
  }, []);

  const uploadFile = useCallback(async (file) => {
    try {
      // Set preview image
      setPreview(URL.createObjectURL(file));

      // Get file extension based on MIME type
      const fileExtension = ACCEPTED_IMG_TYPE[file.type];
      if (!fileExtension) {
        throw new Error(`Unsupported file type: ${file.type}`);
      }

      // Generate unique filename with UUID and extension
      const fileName = `${v4()}.${fileExtension}`;

      const formData = new FormData();
      formData.append("imageFile", file);
      formData.append("fileName", fileName);
    
      const response = await axios.post(
        `${SERVER_URL}${END_POINTS.UPLOAD_FILE}`,
        formData,
        {
          headers: { 
            "Content-Type": "multipart/form-data",
            'apiKey': API_KEY,
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentCompleted); // Update progress
          },
        }
      );

      const data = response.data;

      if(data?.data?.signedUrl){
        sendMessageWithImage(data.data.signedUrl);
        setPreview(null);
        setProgress(0);
      }
    } catch (error) {
      console.log({ error });
    }
  }, []);

  const { getRootProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".jpeg", ".png"],
    },
    multiple: false,
  });

  return (
    <div
      {...getRootProps()}
      onClick={(e) => e.stopPropagation()}
      style={{
        height,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        borderWidth: isDragActive ? 2 : 0,
        borderColor: "#eeeeee",
        borderStyle: "dashed",
      }}
    >
      {preview ? (
        <React.Fragment>
          <div style={{ margin: "10px 0" }}>
            <img
              src={preview}
              alt="Selected file preview"
              style={{ width: "200px", height: "auto", borderRadius: "8px" }}
            />
          </div>
          <div>
            <p style={{ fontSize: 12 }}>{progress}%</p>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <h2 style={{ fontSize: 32, fontWeight: 500, marginBottom: 16 }}>
            {t("chat.startBuilding")}
          </h2>
          <SuggestionButton uploadFile={uploadFile} />
        </React.Fragment>
      )}
    </div>
  );
};

export default React.memo(
  EmptyChat,
  (prev, next) => prev.height === next.height
);
