import React from "react";
import SplitLayout from "../../components/layout/splitLayout";
import Pricing from "../../components/princing";
import MCSideBar from "../../components/sideBar/";
import TutorialAccordions from "./Tutorials";

const TutorialsPage = () => {


  return (
    <SplitLayout
      sideBar={<MCSideBar onCreateNewChat={() => {}} />}
      leftComponent={<TutorialAccordions />}
      rightComponent={null}
    />
  );
};

export default TutorialsPage;
