import React, { Children, useState } from "react";
import "./style.css";

const TooltipButton = ({ children, label, onClick, white }) => {
  const [isVisible, setIsVisible] = useState(false);

  const showTooltip = () => setIsVisible(true);
  const hideTooltip = () => setIsVisible(false);

  return (
    <div
      className="tooltip-container"
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      <button
        className={white ? "icon-button-plus" : "icon-button"}
        onClick={onClick}
        style={
          white && {
            backgroundColor: "#fff",
            borderWidth: 1,
            borderColor: "rgba(0,0,0,.1)",
            borderStyle: "solid",
          }
        }
      >
        {children}
      </button>

      {isVisible && label && <div className="tooltip">{label}</div>}
    </div>
  );
};

export default TooltipButton;
