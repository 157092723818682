import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ChevronDown, ChevronUp } from 'lucide-react';

export default function TutorialAccordions() {
  const [openIndex, setOpenIndex] = useState(null);

  const { t } = useTranslation();

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const tutorials = [
    {
      title: t("tutorials.tutorials.connectAPhysicalDevice.title"),
      description: t("tutorials.tutorials.connectAPhysicalDevice.description"),
      videoId: "84s2B4TUo3w",
    },
    {
      title: t("tutorials.tutorials.connectASimulator.title"),
      description: t("tutorials.tutorials.connectASimulator.description"),
      videoId: "WF2fN1dUIF4",
    },
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-4">{t("tutorials.title")}</h1>
      <p className="text-xl mb-8">{t("tutorials.subtitle")}</p>

      <div className="space-y-2">
        {tutorials.map((tutorial, index) => (
          <div key={index} className="border border-gray-200 rounded-md">
            <button
              className="w-full text-left p-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
              onClick={() => toggleAccordion(index)}
              aria-expanded={openIndex === index}
              aria-controls={`content-${index}`}
            >
              <div className="flex justify-between items-center">
                <span className="text-lg font-semibold">{tutorial.title}</span>
                <span className="transform transition-transform duration-200 ease-in-out">
                  {openIndex === index ? <ChevronUp/> : <ChevronDown/>}
                </span>
              </div>
            </button>
            {openIndex === index && (
              <div id={`content-${index}`} className="p-4">
                <p className="text-sm text-gray-600 mb-4">
                  {tutorial.description}
                </p>
                <div className="aspect-w-16 aspect-h-9">
                  <iframe
                    src={`https://www.youtube.com/embed/${tutorial.videoId}`}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="w-full h-full"
                    style={{ height: 400}}
                  ></iframe>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
