import React from "react";

const MessageBubble = ({ message }) => {
  return (
    <div style={styles.container}>
      {message.content[0].type === "text" && (
        <span style={styles.bubble}>{message.content[0].text}</span>
      )}

      {message.content[0].type === "image_url" && (
        <img
          src={message.content[0].image_url.url}
          alt="img"
          style={styles.img}
        />
      )}
    </div>
  );
};

const styles = {
  container: {
    padding: 10,
    display: "flex",
    justifyContent: "flex-end",
  },
  bubble: {
    backgroundColor: "#f4f4f4",
    padding: 10,
    borderRadius: 10,
    marginTop: 12,
    marginBottom: 12,
    display: "inline-block",
    maxWidth: "80%",
    textAlign: "left",
  },
  img:{
    width: 100,
    aspectRatio: 1,
    borderRadius: 10,
    resizeMode: "cover",
  }
};

MessageBubble.displayName = "MessageBubble";

export default React.memo(MessageBubble);
